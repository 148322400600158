export default {
    methods: {
        statusIntToStr(status, short = false){
            switch(status){
                case 0:
                    if(short){
                        return 'Aucune';
                    }
                    return 'Aucune carte';
                //break;
                case 1:
                    if(short){
                        return 'oui';
                    }
                    return 'Carte active';
                case 2:
                    if(short){
                        return 'en attente';
                    }
                    return 'En attente d\'information';
                case 3:
                    if(short){
                        return 'validation';
                    }
                    return 'En cours de validation';
                case 4:
                    if(short){
                        return 'en livraison';
                    }
                    return 'En cours de livraison';
                default:
                    return '';
            }
        },
        userHasRole(roleName){
                if(window.appData.credentials.user == null || window.appData.credentials.user == undefined){
                    return false;
                }
                return window.appData.credentials.user.roles.includes(roleName);
        }
    }
}
