<template>
	<div class="slider">
		<div class="line"></div>
		<div class="subline inc"></div>
		<div class="subline dec"></div>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

</style>
