<template>
	<div class="p-fluid">
		<InfiniteProgress v-show="loading"></InfiniteProgress>
		<div v-if="userData !== undefined && userData.has_google" class="p-fluid">
			<FullCalendar v-if="evtsData !== undefined"  :events="eventsAll" :options="options" />

			<Dialog v-model:visible="eventDialog" :style="{width: '450px'}" header="Détail événement" :modal="true" :closable="true">
				<div v-if="clickedEvent !== undefined && clickedEvent.extendedProps !== undefined && clickedEvent.extendedProps['@id'] !== undefined" class="p-fluid">
					<EditEvent :guid="clickedEvent.extendedProps['@id']"></EditEvent>
				</div>
			</Dialog>
		</div>
		<div v-else-if="userData !== undefined && (!userData.has_google && userHasRole('ROLE_CALENDAR'))" class="p-fluid p-text-center">
			<small class="text-muted">
				Veuillez connecter votre compte Google dans les options de votre compte.
			</small>
		</div>
		<div v-else-if="userData !== undefined && !userHasRole('ROLE_CALENDAR')" class="p-fluid p-text-center">
			<Message severity="error" :closable="false">
				Vous n'avez pas accès à cette fonctionnalité
			</Message>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import CalendarService from "../../../service/CalendarService";
	import userMixin from "../../../mixins/userMixin";
	import calendarMixin from "../../../mixins/calendarMixin";
	import InfiniteProgress from "../../InfiniteProgress.vue";

	import dayGridPlugin from '@fullcalendar/daygrid';
	import timeGridPlugin from '@fullcalendar/timegrid';
	import interactionPlugin from '@fullcalendar/interaction';

	import EditEvent from '../../../components/app/calendar/EditEvent.vue'

	import moment from 'moment';
	import CrmService from "../../../service/CrmService";

	export default {
		data(){
			return {
				loading: false,
				eventDialog: false,
				clickedEvent: null,
				dateStart: moment().startOf('isoWeek').toDate(),
				dateEnd:  moment().endOf('isoWeek').toDate(),
				changedEvent: {title:'', start: null, end:'', allDay: null},
				options: {
					firstDay: 1,
					locale: 'fr',
					plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
					defaultDate:  moment().startOf('isoWeek').format('YYYY-MM-DD'),
					defaultView: 'timeGridWeek',
					header: {
						left: 'prev,next',
						center: 'title',
						right: 'dayGridMonth,timeGridWeek,timeGridDay'
					},
					slotDuration: {
						minutes: 60
					},
					editable: false,
					businessHours: undefined,
					eventClick: (e) => {
						this.eventDialog = true;
						this.clickedEvent = e.event;
					},
					datesRender: (e) => {
						this.dateStart = e.view.currentStart;
						this.dateEnd = e.view.currentEnd;
						this.loadData();
					}
				},
				/**
				 * @see https://fullcalendar.io/docs/v4/event-parsing
				 */
				evtsData: undefined,
				evtsApp: undefined,
				attendeeDetail: undefined,

			}
		},
		created(){
			this.calendarService = new CalendarService();
			this.crmService = new CrmService();
		},
		mounted(){
			this.waitUser();
		},
		methods:{
			waitUser(){
				const vm = this;
				vm.loading = true;
				if(vm.userData === undefined){
					setTimeout(() => {
						vm.waitUser();
					}, 100);
					return;
				}
				vm.loading = false;
				if(this.userHasRole('ROLE_CALENDAR')){
					this.loadData();
				}
			},
			loadData(){
				if(this.userData !== undefined){
					this.options.slotDuration = {
						minutes: this.userData.timeslotSizeMinute,
					}
				}
				this.loadBH();
			},
			loadBH(){
				this.loading = true;
				this.calendarService.listBusinessHours()
						.then(res => {
							let arr = [];
							res.data.data.forEach(el => {
								el.startTime = el.start;
								el.endTime = el.end;
								arr.push(el)
							});
							this.options.businessHours = arr;
							this.loadEvents();
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loading = false;
						})
			},
			loadEvents(){
				this.loading = true;
				this.calendarService.listEvents(
						this.dateToISO(this.dateStart),
						this.dateToISO(this.dateEnd),
				)
						.then(res => {
							if(res.data.error === true){
								throw new Error('Erreur lors de la requete')
							}
							this.evtsData = res.data.data;
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
						.finally(() => {
							this.loading = false;
						})
				;

				this.calendarService.listCalendarEventFromTo(
						this.dateToISO(this.dateStart),
						this.dateToISO(this.dateEnd),
						[0,1]
				)
						.then(res => {
							this.evtsApp = res.data.data;
						})
						.catch(err => {
							this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
						})
				;
			}
		},
		computed: {
			...mapState({
				userData: 'user',
			}),
			eventsAll(){
				if(this.evtsApp !== undefined){
					return [...this.evtsApp, ...this.evtsData];
				}

				return this.evtsData;
			},
			passwordValid(){
				let passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

				if(this.newPassword === ''){
					return true;
				}

				return this.newPassword.match(passw);
			},
		},
		mixins: [userMixin, calendarMixin],
		components: {
			InfiniteProgress,
			EditEvent,
		}
	}
</script>

<style lang="scss">
	.fc-event {
		&.event-app {
			 background: #808080;
			 border-color: #808080;
			&.pending-owner {
				 background: #038d4a;
				 border-color: #038d4a;
			 }
		}

		&.event-google {
			 background: darkgray;
			 border-color: darkgray;
		 }
	}


</style>
