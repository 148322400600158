<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Calendrier de rendez vous</h1>
			<p class="text-muted">Retrouvez ici toutes les demandes et tentatives de rendez-vous avec vos prospects.</p>
		</div>

		<div class="p-col-12">
			<div class="card">
				<div class="p-fluid p-mt-2">
					<CalendarView></CalendarView>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import CalendarView from '../components/app/calendar/CalendarView.vue';

export default {
	data(){
		return {

		}
	},
	created(){

	},
	components: {
		CalendarView,
	}
}
</script>

<style scoped>

</style>
