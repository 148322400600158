<template>
	<div class="p-grid p-fluid">
		<div class="p-col-12">
			<InfiniteProgress v-if="loading"></InfiniteProgress>
			<div v-if="eventData !== undefined" class="p-fluid">
				<h5>{{ eventData.title }}</h5>
				<p class="text-muted">{{ eventData.text }}</p>

				<div class="p-field">
					<label for="status_te">Statut</label>
					<InputText :value="statusToStr(eventData.status)" id="status_te" type="text" disabled />
				</div>

				<div class="p-field">
					<p class="text-muted">Début</p>
					{{ isoStrToDate(eventData.start) }}
				</div>
				<div class="p-field">
					<p class="text-muted">Fin</p>
					{{ isoStrToDate(eventData.end) }}
				</div>

				<div v-if="attendeeDetail !== undefined" class="p-fluid">
					<div class="p-field">
						<p class="text-muted">Avec</p>
						<a @click.prevent="viewProfile(attendeeDetail)" href="#">
							{{ attendeeDetail.firstname }} {{ attendeeDetail.lastname }}<br>
							<small class="text-muted">{{ attendeeDetail.email }}</small>
						</a>

					</div>
				</div>

				<div v-if="eventData.status === 0" class="p-field">
					<Button label="Accepter"
							@click="validateOwnerEvent"
							icon="pi pi-check" class="p-button-success p-mt-2 p-mb-2"></Button>
					<Button label="Refuser"
							@click="refuseOwnerEvent"
							icon="pi pi-times" class="p-button-danger p-mt-2 p-mb-2"></Button>
				</div>

				<div v-if="eventData.status === 1" class="p-field">
					<Button label="En attente d'une réponse"
							icon="pi pi-check" class="p-button-secondary text-muted p-mt-2 p-mb-2"></Button>
				</div>

				<div v-if="eventData.status === 2" class="p-field">
					<Button label="Annuler"
							@click="cancelOwnerEvent"
							icon="pi pi-times" class="p-button-danger p-mt-2 p-mb-2"></Button>
				</div>


			</div>
		</div>
	</div>
</template>

<script>
	import InfiniteProgress from "../../InfiniteProgress.vue";
import CalendarService from "../../../service/CalendarService";
import CrmService from "../../../service/CrmService";
	import calendarMixin from "../../../mixins/calendarMixin";

export default {
	data(){
		return {
			eventData: undefined,
			attendeeDetail: undefined,
			loading: false,
		}
	},
	props: {
		guid: {},
		loadProfile: {
			type: Boolean,
			default: true,
		}
	},
	created(){
		this.calendarService = new CalendarService();
		this.crmService = new CrmService();
		this.loading = true;
	},
	mounted(){
		this.loadEventData();
	},
	methods: {
		loadEventData(){
			this.loading = true;
			this.calendarService.getCalendarEvent(this.guid)
					.then(res => {
						if(res.data.error === true){
							throw new Error('Erreur lors de la requete')
						}
						this.loading = false;
						this.eventData = res.data;
						this.loadAttendeeDetail();
					})
					.catch(err => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
		},
		refuseOwnerEvent(){
			const payload = {
				status: 5,
			};
			this.loading = true;

			this.calendarService.updateCalendarEvent('/api/calendar_events/' + this.eventData.id,payload)
					.then(res => {
						if(res.data.error === true){
							throw new Error('Erreur lors de la requete')
						}
						this.loading = false;
						this.loadEventData();
					})
					.catch(err => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
		},
		validateOwnerEvent(){
			const payload = {
				status: 2,
			};
			this.loading = true;

			this.calendarService.updateCalendarEvent('/api/calendar_events/' + this.eventData.id,payload)
					.then(res => {
						if(res.data.error === true){
							throw new Error('Erreur lors de la requete')
						}
						this.loading = false;
						this.loadEventData();
					})
					.catch(err => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
		},
		cancelOwnerEvent(){
			const payload = {
				status: 3,
			};
			this.loading = true;

			this.calendarService.updateCalendarEvent('/api/calendar_events/' + this.eventData.id,payload)
					.then(res => {
						if(res.data.error === true){
							throw new Error('Erreur lors de la requete')
						}
						this.loading = false;
						this.loadEventData();
					})
					.catch(err => {
						this.loading = false;
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
		},
		loadAttendeeDetail(){
			// cancel loading if option is setted to false
			if(this.loadProfile === false){
				return;
			}

			this.loading = true;
			if(this.eventData.attendee === undefined || this.eventData.attendee === null){
				return;
			}
			this.crmService.getContact(this.eventData.attendee)
					.then(res => {
						if(res.data.error === true){
							throw new Error('Erreur lors de la requete')
						}
						this.attendeeDetail = res.data;
					})
					.catch(err => {
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
		},
		viewProfile(contact) {
			this.$router.push({ name: 'crm-contacts-profile', params: { guid: contact.guid } })
		}
	},
	components: {
		InfiniteProgress,
	},
	mixins: [calendarMixin]
}
</script>

<style scoped>

</style>
